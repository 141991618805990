import { Card, ECardBorderRadiusSize } from '@outdoorsyco/bonfire';
import { clsx } from 'clsx';
import escapeHtml from 'escape-html';
import React, { AnchorHTMLAttributes } from 'react';

import Link from '@/components/switchback/Link/Link';
import { ISearchResultTile } from '@/utility/mapSearchResultToTile';

import { HorizontalListingTileContent } from './HorizontalListingTileContent';
import { ListingTileContent } from './ListingTileContent';
import { ListingTileMedia } from './ListingTileMedia';

export enum EListingTileVariant {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

type TListingTileProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  clientSideRouting?: boolean;
  rentalTile: ISearchResultTile;
  isOutlined?: boolean;
  showFirstImageOnly?: boolean;
  addFavorite?: (id: number) => void;
  removeFavorite?: (id: number) => void;
  onSlideChange?: (nextIndex: number) => void;
  variant?: EListingTileVariant;
  displayStationaryCamperPromo?: boolean;
  customRentalTitle?: string;
  showGuestFavorite?: boolean;
  withPriority?: boolean;
};

export const ListingTile = ({
  clientSideRouting,
  rentalTile,
  isOutlined,
  showFirstImageOnly,
  addFavorite,
  removeFavorite,
  onSlideChange,
  variant = EListingTileVariant.Vertical,
  displayStationaryCamperPromo,
  customRentalTitle = '',
  showGuestFavorite = false,
  withPriority = false,
  ...props
}: TListingTileProps) => {
  const escapedTitle = rentalTile.title && escapeHtml(rentalTile.title);
  const isHorizontal = variant === EListingTileVariant.Horizontal;
  let card = (
    <Card
      borderRadiusSize={ECardBorderRadiusSize.Large}
      showHoverEffect={false}
      withBoxShadow={false}
      withTransparentBackground={true}
      aria-label={displayStationaryCamperPromo ? customRentalTitle : escapedTitle}
      title={displayStationaryCamperPromo ? customRentalTitle : escapedTitle}
      href={rentalTile.linkUrl}
      className={clsx({
        '!border-neutral-20 !border-solid !border': isHorizontal,
        'outline outline-2 outline-offset-2 outline-green-500': isOutlined && isHorizontal,
      })}
      {...props}>
      <div
        className={clsx('flex', {
          'flex-row': isHorizontal,
          'flex-col': !isHorizontal,
        })}>
        <div
          className={clsx({ 'w-2/5 rounded-l-2xl': isHorizontal, 'rounded-2xl': !isHorizontal })}>
          <ListingTileMedia
            rentalTile={rentalTile}
            isOutlined={isOutlined && !isHorizontal}
            showFirstImageOnly={showFirstImageOnly}
            addFavorite={addFavorite}
            removeFavorite={removeFavorite}
            onSlideChange={onSlideChange}
            showLimitedOfferTag={displayStationaryCamperPromo}
            isHorizontal={variant === EListingTileVariant.Horizontal}
            showGuestFavorite={showGuestFavorite}
            withPriority={withPriority}
          />
        </div>

        {isHorizontal ? (
          <HorizontalListingTileContent
            rentalTile={rentalTile}
            customTitle={displayStationaryCamperPromo ? customRentalTitle : ''}
          />
        ) : (
          <ListingTileContent
            rentalTile={rentalTile}
            customTitle={displayStationaryCamperPromo ? customRentalTitle : ''}
          />
        )}
      </div>
    </Card>
  );

  if (displayStationaryCamperPromo) {
    card = <div className="p-4 bg-green-30 rounded-2xl">{card}</div>;
  }

  if (clientSideRouting && rentalTile.linkUrl) {
    return (
      <Link href={rentalTile.linkUrl} legacy forceClientsideRouting passHref>
        {card}
      </Link>
    );
  }

  return card;
};
